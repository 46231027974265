import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import actionStatus from '@/client/actions/actionStatus';

import { nextPageProps } from '@/HOC/utils/nextProps';

import AppShell from '@/HOC/components/AppShell';

import getCanonicalUrl from '@/HOC/utils/seo/getCanonicalUrl';
import getBreadcrumbList from '@/HOC/utils/seo/getBreadcrumbList';
import getLdSchema from '@/HOC/utils/seo/getLdSchema';
import categoryTypes from '@/server/constants/categoryTypes';
import getIsMobile from '@/client/utils/browser/isMobile';
import CATEGORIES_L1 from '@/constants/categories';

import updateDataLayerAndInitGA from '@/HOC/utils/updateDataLayerAndInitGA';
import fetchSearchResultsAndUpdateState from '@/HOC/utils/fetchSearchResultsAndUpdateState';
import { TRelevantInlink } from '@ecg-marktplaats/express-react/dist/types';
import isSsr from '@/client/utils/isSsr';

const LrpContainer = React.lazy(() => import('@/client/containers/pages/LrpContainer'));

interface LrpProps {
  generationTimeMillis: number;
  requestStatus: string;
  searchRequestAndResponse: TSearchRequestAndResponse;
  headerProps: object;
  categories: TCategories;
  seller: TSeller;
  relevantInlinks: TRelevantInlink[];
  faqData: any[];
  paaData: any[];
  internalLinks: any;
  hubPage: any;
  priceSuggestions: any[];
  page: number;
  meta: TSeoMetaTags;
  hrefLangUrl: string;
}

const WrappedLRP = ({
  generationTimeMillis,
  requestStatus = actionStatus.initial,
  searchRequestAndResponse,
  headerProps,
  categories,
  seller = null,
  ...props
}: LrpProps) => {
  const { tenantContext } = useContext(EnvironmentContext);
  const didMountRef = useRef(false);

  const [requestStatusState, setRequestStatusState] = useState(requestStatus);
  const [generationTimeMillisState, setGenerationTimeMillisState] = useState(generationTimeMillis);
  const [searchRequestAndResponseState, setSearchRequestAndResponseState] = useState(searchRequestAndResponse);
  const isCarsCategory = Boolean(searchRequestAndResponse.categoriesById[CATEGORIES_L1.CARS.toString()]);

  const router = useRouter();
  const { query, asPath } = router;
  const { locale } = tenantContext;

  useEffect(() => {
    if (isSsr()) return;
    const handleRouteChange = () => {
      if (isCarsCategory && !getIsMobile() && window.preventScroll) {
        window.preventScroll = false;
        return;
      }
      window.scrollTo(0, 0);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('hashChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('hashChangeStart', handleRouteChange);
    };
  }, [router, isCarsCategory]);

  const fetchSearchResults = useCallback(async () => {
    await fetchSearchResultsAndUpdateState(
      query,
      locale,
      setRequestStatusState,
      setGenerationTimeMillisState,
      setSearchRequestAndResponseState,
    );
  }, [locale, query]);

  useEffect(() => {
    if (isSsr()) return;
    // On other than first render
    // which will happen when asPath (URL) is changed
    if (didMountRef.current) {
      // fetch new listings (also fires GA)
      fetchSearchResults();
    }

    // On first render
    if (!didMountRef.current) {
      didMountRef.current = true;

      if (window.location.hash.length) {
        // is hash query params present in first render
        // Yes, hash present
        // fetch new listings with hash params (also fires GA)
        fetchSearchResults();
      } else {
        // No, hash not present
        // server side rendered results will be shown, just fire GA
        updateDataLayerAndInitGA(searchRequestAndResponseState, locale);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, asPath, fetchSearchResults]);

  const {
    attributeHierarchy,
    searchRequest = {},
    searchCategory,
    searchCategoryOptions,
    seoFriendlyAttributes,
    alternativeLocales,
  } = searchRequestAndResponseState;

  return (
    <AppShell
      headerProps={headerProps}
      searchRequest={searchRequest}
      attributeHierarchy={attributeHierarchy}
      seoFriendlyAttributes={seoFriendlyAttributes}
      searchCategory={searchCategory}
      searchCategoryOptions={searchCategoryOptions}
      alternativeLocales={alternativeLocales}
      seller={seller}
    >
      <LrpContainer
        {...props}
        requestStatus={requestStatusState}
        generationTimeMillis={generationTimeMillisState}
        searchRequestAndResponse={searchRequestAndResponseState}
        headerProps={headerProps}
        categories={categories}
        seller={seller}
      />
    </AppShell>
  );
};

WrappedLRP.getInitialProps = ({ res, req }) => {
  // when the user presses the back button, this getInitialProps gets called.
  // No req/res is available, so we need to reconstruct the returned props from another source
  if (process.browser && !req && !res) {
    return {
      ...nextPageProps(),
    };
  }

  const { hostname, originalUrl } = req;
  const hrefLangUrl = `https://${hostname}${originalUrl}`;

  const {
    pagination,
    headerProperties,
    searchRequestAndResponse,
    faqData,
    paaData,
    internalLinks,
    hubPage,
    eligibleForCategoryRedirectServerSide,
    relevantInlinks,
    priceSuggestions,
  } = res.locals;

  const page = pagination ? pagination.offset / pagination.limit : null;

  const initialProps = {
    headerProps: headerProperties,
    hrefLangUrl,
    ...(pagination && { page }),
  };

  let dominantCategory = {};
  if (!searchRequestAndResponse) {
    initialProps.searchRequestAndResponse = {};
    initialProps.requestStatus = actionStatus.failure;
  } else {
    initialProps.searchRequestAndResponse = searchRequestAndResponse;
    initialProps.requestStatus = actionStatus.success;
    initialProps.generationTimeMillis = new Date().getTime();
    initialProps.faqData = faqData;
    initialProps.paaData = paaData;
    initialProps.internalLinks = internalLinks;
    initialProps.hubPage = hubPage;
    initialProps.eligibleForCategoryRedirectServerSide = eligibleForCategoryRedirectServerSide;
    initialProps.relevantInlinks = relevantInlinks;
    initialProps.priceSuggestions = priceSuggestions;

    const categoryTree = searchRequestAndResponse.facets.find(({ type }) => type === categoryTypes.CategoryTreeFacet);

    if (categoryTree && categoryTree.categories) {
      dominantCategory = categoryTree.categories && categoryTree.categories.find((obj) => obj.dominant);
    }
  }

  const meta = {
    canonicalUrl: getCanonicalUrl(req, dominantCategory),
    breadcrumbs: getBreadcrumbList(req, res),
  } as TSeoMetaTags;

  meta.product = getLdSchema(req, res) as TMetaProduct;

  initialProps.meta = meta;

  return initialProps;
};

export default WrappedLRP;
