import { logger } from '@lrp/logger';
import assembleSearchRequest from '@/HOC/utils/assembleSearchRequest';
import fetchSearchResults from '@/client/actions/search/fetchSearchResults';
import actionStatus from '@/client/actions/actionStatus';
import updateDataLayerAndInitGA from '@/HOC/utils/updateDataLayerAndInitGA';

// eslint-disable-next-line max-params
export default async function fetchSearchResultsAndUpdateState(
  query,
  locale,
  setRequestStatusState,
  setGenerationTimeMillisState,
  setSearchRequestAndResponseState,
) {
  const updatedSearchObject = assembleSearchRequest(query);
  setRequestStatusState(actionStatus.fetching);

  try {
    const searchResults = await fetchSearchResults(updatedSearchObject);

    setRequestStatusState(actionStatus.success);
    setGenerationTimeMillisState(new Date().getTime());
    setSearchRequestAndResponseState(searchResults);
    updateDataLayerAndInitGA(searchResults, locale);
  } catch (err) {
    logger.error('error in withSearchResults - fetchSearchResultsAndUpdateState', err);
    setRequestStatusState(actionStatus.failure);
  }
}
